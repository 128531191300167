import { Link } from "react-router-dom";
import Utils from "./Utils";
import config from "./config.json";

import { useWeb3Modal } from "@web3modal/react";
import { useAccount, useNetwork, useSwitchNetwork } from "wagmi"

export default function Header() {
    const { open } = useWeb3Modal();
    const { address } = useAccount();
    const { chain } = useNetwork();
    const { switchNetwork } = useSwitchNetwork();

    const onClick = () => {
        if (!address) {
            open();
            return;
        }

        if (chain?.id != config.chainId) {
            switchNetwork(config.chainId);
            return;
        }

        open();
    }

    const getButtonText = () => {
        if (!address) {
            return "CONNECT";
        }

        if (chain?.id != config.chainId) {
            return "SWITCH CHAIN"
        }

        return Utils.fancyHash(address);
    }

    return (
        <header id="header">
            <div>
                <Link to="/">
                    <img data-aspectratio="321/49"
                        src="./VictoriaVRLogo.svg" 
                        alt="loading" height="36" width="300" loading="lazy"> 
                    </img>  
                </Link>
                <div id="header-right">
                    <button className="connect button" onClick={async(e) => onClick()}>
                        {getButtonText()}
                    </button>
                </div>
            </div>
        </header>
    )
}